<template>
  <main class="dapp">
    <section>
      <h1>Скачать приложение на ПК и Смартфон</h1>

      <div class="download-variants">
        <div class="row">
          <a href="https://1xkino.ru/static_files/app/1X-1.0.1-win64.zip" download>
            <img src="/static_files/icons/computer.png" alt="App for Win64">
            <strong>Windows 64bit</strong>
          </a>
          <a href="https://1xkino.ru/static_files/app/1X-1.0.1-mac.zip" download>
            <img src="/static_files/icons/apple.png" alt="App for Mac64">
            <strong>Mac 64bit</strong>
          </a>
          <a href="https://1xkino.ru/static_files/app/1X-1.0.1-arm64-mac.zip" download>
            <img src="/static_files/icons/apple.png" alt="App for Mac64">
            <strong>Mac Arm 64bit</strong>
          </a>
        </div>
        <div class="row">
          <a href="https://1xkino.ru/static_files/app/1X-1.0.1-android.zip" download>
            <img src="/static_files/icons/android-os.png" alt="App for Android">
            <strong>Android</strong>
          </a>
        </div>
      </div>

      <!-- <div class="desc">
        Скачайте приложение для ПК и смартфона и наслаждайтесь просмотром любимых фильмов в любое время и в любом месте! Наше приложение предлагает бесплатные загрузки, высокое качество видео, удобный и интуитивно понятный интерфейс, а также широкий выбор кинофильмов и сериалов. Независимо от того, предпочитаете ли вы смотреть новинки кино или классику, у нас найдется что-то для каждого. Поддержка всех популярных форматов, регулярные обновления контента и возможность оффлайн просмотра делают наше приложение идеальным выбором для киноманов. Установите сейчас и погрузитесь в захватывающий мир кино с комфортом!
      </div> -->
    </section>
  </main>
</template>

<script>
export default {
  head() {
    const title = 'Скачать приложение для просмотра онлайн на PC, Android, MacOS'
    const description = 'Приложение для просмотра фильмов, сериалов и аниме для разных платформ: PC, Android, MacOS без рекламы.'
    const poster = '/static_files/apple-icon.png'
    const canonical = `https://1xkino.ru/app`

    return {
      title: title,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: canonical
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `приложение для просмотра кино онлайн`
        },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: canonical
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: poster
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: title
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: description
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content: poster
        }
      ]
    }
  },
}
</script>

<style lang="stylus">
.dapp
  .desc
    margin-top: 60px
  h1
    margin-bottom: 30px
  .download-variants
    margin: 0px 0px 20px
    .row
      display grid
      grid-template-columns: repeat(3, 1fr)
      grid-gap: 10px
      margin: 0px 0px 10px
      a
        display grid
        grid-template-columns: 24px auto
        grid-gap: 10px
        border: 1px solid rgba(255, 255, 255, 0.15)
        border-radius: 5px
        padding: 10px
        align-items: center
        justify-content: center
        img
          width: 24px
          height: 24px
</style>
